<template>
  <v-main>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
    <v-form @submit.prevent="doProcess">
    <v-row>
      <v-col
        class="text-right"
        cols="4"
      >
      <validation-provider
                v-slot="{ errors }"
                name="API Key"
                rules="required"
              >
        <v-text-field v-model="shoppyData.shoppy_api" label="API"  :error-messages="errors">
              <v-icon
                  slot="prepend"
              >
                  mdi-api
              </v-icon>
        </v-text-field>
      </validation-provider>
      <validation-provider
                  v-slot="{ errors }"
                  name="Webhook Secret"
                  rules="required"
                >
          <v-text-field v-model="shoppyData.shoppy_secret" label="Webhook Secret" :error-messages="errors">
                <v-icon
                    slot="prepend"
                >
                    mdi-key-star
                </v-icon>
          </v-text-field>
        </validation-provider>      
        <validation-provider
                  v-slot="{ errors }"
                  name="Rate Exchange"
                  rules="required|decimal"
                >
          <v-text-field v-model="shoppyData.shoppy_rate" label="Rate Exchange" :error-messages="errors" hint="Example: 1.5 is $1 Shoppy get $1.5 Balance" persistent-hint>
                <v-icon
                    slot="prepend"
                >
                    mdi-percent
                </v-icon>
          </v-text-field>
        </validation-provider>
        <validation-provider
                  v-slot="{ errors }"
                  name="Shoppy.GG Footer"
                >
          <v-textarea v-model="shoppyData.shoppy_footer" label="Shoppy.GG Footer" :error-messages="errors">
                <v-icon
                    slot="prepend"
                >
                    mdi-format-text-variant
                </v-icon>
          </v-textarea>
        </validation-provider>
        <br />
        <p>
        <v-btn-toggle>
          <v-btn value="left" color="success" @click="doAddNewPackage">
              <span class="hidden-sm-and-down">New Package</span>
              <v-icon right>
                mdi-plus
              </v-icon>
          </v-btn>
          <v-btn value="left" color="primary" type="submit" :disabled="invalid">
              <span class="hidden-sm-and-down">Update</span>
              <v-icon right>
              mdi-content-save-move
              </v-icon>
          </v-btn>
        </v-btn-toggle>
        </p>
      </v-col>
      <v-col
        class="text-right"
        cols="1"
      >
        <v-checkbox
          v-model="shoppyData.shoppy_payments"
          label="PayPal"
          value="PayPal"
          prepend-icon="fab fa-paypal"
        >
        </v-checkbox>
        <v-checkbox
          v-model="shoppyData.shoppy_payments"
          label="BTC"
          value="Bitcoin"
          prepend-icon="fab fa-bitcoin"
        ></v-checkbox>
        <v-checkbox
          v-model="shoppyData.shoppy_payments"
          label="ETH"
          value="Ethereum"
          prepend-icon="fab fa-ethereum"
        ></v-checkbox>
        <v-checkbox
          v-model="shoppyData.shoppy_payments"
          label="LTC"
          value="Litecoin"
          prepend-icon="mdi-litecoin"
        ></v-checkbox>
      </v-col>
      <v-col
        class="text-right overflow-y-auto"
        cols="2"
        max-height="400"
      >
        <p
          v-for="(data, index) in shoppyData.topup_package"
          :key="index"
        >
        <validation-provider
                v-slot="{ errors }"
                name="Package Price"
                rules="required"
              >
          <v-text-field v-model="shoppyData.topup_package[index]" v-bind:label="PackageName(data)" append-icon="fas fa-times" @click:append="doRemovePackage(data)" :error-messages="errors">
              <v-icon
                  slot="prepend"
              >
                  mdi-wallet
              </v-icon>
          </v-text-field>
        </validation-provider>
        </p>
      </v-col>
    </v-row>
    </v-form>
    </validation-observer>
    <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
    <v-row>
      <v-col
        class="text-right"
        cols="3"
      >
        <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
        <v-text-field v-model="shoppyAPIData.key_name" label="Mokka" hint="Name of key" persistent-hint :error-messages="errors">
              <v-icon
                  slot="prepend"
              >
                  mdi-api
              </v-icon>
        </v-text-field>
      </validation-provider>
        <v-checkbox
          v-model="shoppyAPIData.PackageOnKey"
          label="Show Package On Key"
          prepend-icon="fas fa-box"
        ></v-checkbox>
        <v-select
          :items="keyFormat"
          v-model="shoppyAPIData.key_format"
          label="Key Format"
          prepend-icon="fas fa-heading"
          item-text="text"
          item-value="id"
        ></v-select>
        <v-select
          :items="packages"
          v-model="shoppyAPIData.key_package"
          label="Key Package"
          prepend-icon="fas fa-box"
        ></v-select>
        <validation-provider
                v-slot="{ errors }"
                name="User"
                rules="required"
              >
        <v-select
          :items="sellers"
          v-model="shoppyAPIData.ProductSeller"
          label="Created As User"
          prepend-icon="fas fa-user"
          :error-messages="errors"
          item-text="username"
          item-value="seller_id"
        ></v-select>
      </validation-provider>
      </v-col>
      <v-col class="text-center" cols="12">
          <v-text-field v-model="shoppyAPI" label="SHOPPY API URL" v-if="shoppyAPI">
              <v-icon
                  slot="prepend"
              >
                  mdi-api
              </v-icon>
          </v-text-field>
        <v-btn value="left" color="primary" :disabled="invalid" @click="doGenAPI">
              <span class="hidden-sm-and-down">Generator API</span>
              <v-icon right>
                mdi-api
              </v-icon>
        </v-btn>
      </v-col>
    </v-row>
        </validation-observer>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { required, numeric  } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
  extend('decimal', {
    ...numeric,
    message: '{_field_} must bee numeric. Example: 1, 1.5',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
export default {
  name: 'Shoppy',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
      ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    PackageName(v){
      return `Package ${v}`
    },
    fetchSellers: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
        this.sellers = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/shoppy`,request).then((json)=>{
          this.shoppyData = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doProcess: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/shoppy`,this.shoppyData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doRemovePackage(data){
      let index = this.shoppyData.topup_package.indexOf(data);
      if (index > -1) {
        this.shoppyData.topup_package.splice(index, 1);
      }
    },
    doAddNewPackage(){
      this.shoppyData.topup_package.push("");
    },
    doGenAPI(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/shoppy-api`,this.shoppyAPIData).then((json)=>{
          this.shoppyAPI = json.url;  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    }
  },
  data : () => ({
    shoppyData: {},
    shoppyAPI: "",
    shoppyAPIData: {key_format:"Name_",key_package:"7DAY"},
    Overlay: false,
    packages:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
    keyFormat: [{id:1,text:"Name_"},{id:2,text:"Name-"},{id:3,text:"Name"}],
    sellers: [],
  }),
  watch:{

  },
  mounted(){
    this.fetchData();
    this.fetchSellers();
  }
}
</script>
<style>
</style>