<template>
  <v-container fluid>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left green--text text-h6">
            Player ID ({{players.length}}) - AUTO REFRESH 30s
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in players"
            :key="item"
        >
          <td>{{ item }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  name: "Players",
  data(){
    return {
      players: [],
    }
  },
  methods:{
    doFetch(){
      return fetch("https://backend.egmokka.com/players/list", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      })
      .then(response => response.json())
          .then(json=>this.players = json)
          .finally(()=>{
            setTimeout(()=>{
              this.doFetch();
            },30000);
          });
    }
  },
  mounted() {
    this.doFetch();
  }
}
</script>

<style scoped>

</style>