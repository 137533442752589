<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <div class="ma-1 pa-1">
    <v-form @submit.prevent="doProcess">
    <v-row>
      <v-col
        class="text-right"
        cols="12"
      >
        <h1 class="display-1 font-weight-thin mb-4">
          News
        </h1>
        <h4 class="subheading">
          <quill-editor
            v-model="newsData.news"
            :options="editorOption"
            class="text-left"
          ></quill-editor>
          <p>
            <v-checkbox
              v-model="newsData.show_news"
              label="Show Seller Panel ?"
            ></v-checkbox>
          </p>
        </h4>
      </v-col>
      <v-col
        class="text-right"
        cols="12"
      >
        <h1 class="display-1 font-weight-thin mb-4">
          Client News
        </h1>
        <h4 class="subheading">
          <v-textarea
            v-model="newsData.news_client"
            class="text-left"
          ></v-textarea>
        </h4>
      </v-col>
      <v-col
        class="text-right"
        cols="12"
      >
        <h1 class="display-1 font-weight-thin mb-4">
          System Status
        </h1>
        <v-row>
          <v-col
            cols="4"
          >
            <h4>
              <v-switch
                v-model="newsData.system_off"
                :error="newsData.system_off"
                :success="newsData.system_off === false"
                :label="newsData.system_off ? 'SYSTEM OFF':'SYSTEM ONLINE'"
              />
            </h4>
            <h4 class="subheading" v-if="newsData.system_off">
              <v-textarea
                v-model="newsData.system_off_message"
                class="text-left"
              />
            </h4>
          </v-col>
          <v-col
              cols="4">
            <h4 class="mx-2">
              <v-select
                  v-model="newsData['shop_seller']"
                  :items="sellers"
                  item-value="seller_id"
                  item-text="username"
                  label="Seller"
                  hint="Seller Mini Store"
                  persistent-hint
									multiple
              />
            </h4>
          </v-col>
          <v-col
              cols="4">
            <h4 class="mx-2">
              <v-select
                  v-model="newsData.seller"
                  :items="sellers"
                  :menu-props="{ maxHeight: '400' }"
                  item-value="username"
                  item-text="username"
                  label="Seller"
                  multiple
                  hint="Exclude Seller"
                  persistent-hint
              />
            </h4>
          </v-col>
        </v-row>
      </v-col>
      <v-col
          class="text-right"
          cols="12"
      >
        <h1 class="display-1 font-weight-thin mb-4">
          System Configs
        </h1>
        <v-row>
          <v-col cols="auto">
            <v-text-field
              v-model="newsData.protection"
              label="Protection"
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
                v-model="newsData.blacklist"
                label="Blacklist"
            />
          </v-col>
          <v-col cols="auto">
            <v-text-field
                v-model="newsData.work_ver"
                label="Work Ver"
            />
          </v-col>
            <v-col cols="auto">
                <v-text-field
                        v-model="newsData.limit_hwid_reset"
                        label="Limit HWID Reset"
                />
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="newsData.capture_image"
                :error="newsData.capture_image === false"
                :success="newsData.capture_image === true"
                :label="newsData.capture_image ? 'Capture Image: ON':'Capture Image: OFF'"
              />
            </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-col
        class="text-right"
        cols="12"
      >
      <v-btn value="left" color="primary" type="submit">
          <span class="hidden-sm-and-down">Update</span>
          <v-icon right>
          mdi-content-save-move
          </v-icon>
      </v-btn>
    </v-col>
    </v-form>
    </div>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
export default {
  name: 'News',
  components: {
    quillEditor
  },
  computed: {
      ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    fetchSellerData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/get`,request).then((json)=>{
        this.sellers = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    fetchData: function(request){
      this.Overlay = true;
      return fetchData(`${this.$API}/api/system`,request).then((json)=>{
          this.newsData = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doProcess: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/system`,this.newsData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
  },
  data : () => ({
    newsData: {
      shop_price:{},
      protection: 1000,
      blacklist: true,
      work_ver: '',
      limit_hwid_reset: 2,
      capture_image: false,
    },
    Overlay: false,
    editorOption: {

    },
    sellers: [],
  }),
  watch:{

  },
  mounted(){
    this.fetchData().then(this.fetchSellerData);
  }
}
</script>
<style>
</style>